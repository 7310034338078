import React from 'react';
import wpp from '../assets/wpp.png';
import ReactGA from 'react-ga4';

function WhatsAppButton() {
  const phoneNumber = '+573244823917';

  const handleWhatsAppClick = () => {
    ReactGA.event({
      category: 'Contacto',
      action: 'Click en WhatsApp',
      label: 'WhatsApp Directo'
    });

    const url = `https://wa.me/${phoneNumber}?text=Hola!%20Quisiera%20más%20información.`;
    const callback = function () {
      if (typeof(url) !== 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      'send_to': 'AW-11003239540/v7w4CMeS0s8ZEPS44P4o',
      'value': 15000.0,
      'currency': 'COP',
      'event_callback': callback
    });

    return false;
  };

  return (
    <div onClick={handleWhatsAppClick} className="font-bold font-secondary flex items-center justify-center bg-[#455a64] text-white text-lg/9 px-4 py-3 rounded hover:bg-[#37474f] cursor-pointer">
      <img src={wpp} alt="Whatsapp" className="mr-2 h-6 w-6" />
      <a href={`https://wa.me/${phoneNumber}?text=Hola!%20Quisiera%20más%20información.`} target="_blank" rel="noopener noreferrer" className="text-white">
        Agenda a través de Whatsapp
      </a>
    </div>
  );
}

export default WhatsAppButton;
