import React from 'react';

function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className="bg-gray-800 text-white p-24 mt-4">
      <div className="container mx-auto text-center md:text-left">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <p className="font-bold text-lg">Endomédicos</p>
            <p>© {year} Endomédicos. Todos los derechos reservados.</p>
          </div>
          <div className="md:flex gap-8">
            <div>
              <h6 className="font-semibold">Explora</h6>
              <ul className="list-none">
                <li><a href="/blog" className="hover:underline">Lee nuestro blog</a></li>
                <li><a href="/contacto" className="hover:underline">Contacto</a></li>
              </ul>
            </div>
            <div>
              <h6 className="font-semibold">Participa</h6>
              <ul className="list-none">
                <li><a href="/colabora" className="hover:underline">¿Quieres aparecer en esta página?</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="text-center text-sm mt-4 md:mt-0">
          <p>Visítanos para más información sobre endocrinología y salud.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
