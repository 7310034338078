import React from 'react';
import ButtonComponent from './Button';
import ReactGA from 'react-ga4'; 

const MapsButton = () => {
  const address = "Cra+18%2379-40+Bogota";
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${address}`;

  const handleMapsClick = () => {
    ReactGA.event({
      category: 'Maps',
      action: 'Ver en Google Maps',
      label: 'Dirección en Maps'
    });

    window.open(googleMapsUrl, '_blank');
  };

  return (
    <ButtonComponent
      text="Ver en Google Maps"
      paddingX="20"
      paddingY="15"
      letterSize="16"
      isBold={true}
      onClick={handleMapsClick} 
    />
  );
};

export default MapsButton;
