import WhatsAppButton from "../components/WhatsAppButton";
import direccion from '../assets/direccion.png';
import ButtonComponent from "../components/Button";
import MapsButton from "../components/MapsButton";

const Contacto = () => {
    return (
        <div className="bg-white text-black">
            <h2 className=" bg-white font-primary  text-4xl lg:text-5xl text-center mt-24 mb-10 ">Agenda tu cita</h2>
            <div className="mx-auto w-4/12 border-b border-black -mt-5"></div>
            <div className='max-w-80 mx-auto my-20 '>
                <WhatsAppButton />
            </div>


            <div className="flex flex-col md:flex-row gap-2 md:gap-4 mb-10 lg:mb-32 ">
                <div className="flex-1 p-4 text-center max-w-lg mx-auto">
                    <h1 className="text-3xl font-normal mb-10">Endomédicos - Dra. Teresita Cortes Segura</h1>
                    <p className="text-lg mb-10">Cra. 18 #79-40 consultorio 404, Localidad de Chapinero, Bogotá, Colombia</p>
                    <p className="text-lg"><strong>Teléfono:</strong> 3244823917</p>
                    <p className="text-lg"><strong>Correo:</strong> endomedicos@gmail.com</p>
                </div>

                <div className="flex-1 p-4 max-w-lg mx-auto">
                    <h2 className="text-3xl font-normal mb-10 text-center">Horario</h2>
                    <ul className="text-center text-lg">
                        <li>Lunes: 08:30 a.m. – 04:00 p.m.</li>
                        <li>Martes: 08:30 a.m. - 04:00 p.m.</li>
                        <li>Miércoles: 08:30 a.m. – 04:00 p.m.</li>
                        <li>Jueves: 08:30 a.m. – 04:00 p.m.</li>
                        <li>Viernes: 08:30 a.m. – 04:00 p.m.</li>
                        <li>Sábado: 09:00 a.m. – 12:00 p.m.</li>
                        <li>Domingo: Cerrado</li>
                    </ul>
                    <p className="text-center text-lg">Pide la cita con anterioridad desde Whatsapp</p>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row w-full">
                <div className="w-full lg:w-1/2 max-h-[40vh] lg:max-h-full overflow-hidden">
                    <div className="flex-1 p-4 text-center max-w-lg mx-auto">
                        <h1 className="text-3xl font-normal mb-10">Endomédicos</h1>
                        <p className="text-lg mb-10">Cra. 18 #79-40, torre b, consultorio 404, Localidad de Chapinero, Bogotá, Colombia</p>
                        <MapsButton />
                    </div>

                </div>
                <div className="w-full lg:w-1/2 flex flex-col justify-center items-center overflow-auto">
                    <img src={direccion} alt="Consultorio ubicado en la cra 18#79-40 cons 404" className="w-full h-full object-cover" />

                </div>
                <div className="hidden lg:block absolute left-3/4 translate-x-[-250%] translate-y-20 w-3/12 border-b border-black z-10"></div>
            </div>

        </div>

    );
}
export default Contacto;