import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import Contacto from './pages/Contacto';
import ScrollToTop from './components/ScrollToTop';
import About from './pages/About';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-WZ90QNCMS1'); 

function PageTracker() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
  return null;
}

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Navbar />
        <PageTracker /> 
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/Sobre-nosotros" element={<About />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
