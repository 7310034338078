import React, { useState } from 'react';
import logo from '../assets/endomedicosLogo.png';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';


const Navbar = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };

    const navigate = useNavigate();

    return (
        <nav className="font-secondary bg-white text-black p-5 select-none fixed top-0 left-0 right-0 z-40">
            <div className="lg:hidden flex items-center justify-between">
                <div className="drawer flex w-full">
                    <input id="my-drawer" type="checkbox" className="drawer-toggle" checked={isOpen} onChange={toggleDrawer} />
                    <div className="drawer-content flex items-center justify-between">

                        <button onClick={toggleDrawer} className="text-black">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                className="h-5 w-5 stroke-current"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                ></path>
                            </svg>
                        </button>

                    </div>
                    <div className="flex-1 flex justify-center">
                        <Link to="/">
                            <img src={logo} alt="Logo" className="h-10" />
                        </Link>
                    </div>
                    <div className="drawer-side">
                        <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
                        <ul className="menu bg-base-200 text-base-content min-h-full w-80 p-4">
                            <li>
                                <Link
                                    to="/"
                                    className="hover:bg-gray-150"
                                    onClick={() => setIsOpen(false)}
                                >
                                    Inicio
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/"
                                    className="hover:bg-gray-150"
                                    onClick={() => setIsOpen(false)}
                                >
                                    Servicios
                                </Link></li>
                            <li>
                                <Link
                                    to="/contacto"
                                    className="hover:bg-gray-150"
                                    onClick={() => setIsOpen(false)}
                                >
                                    Contacto
                                </Link></li>
                            <li><a>Blog</a></li>
                            <li>
                                <Link
                                    to="/Sobre-nosotros"
                                    className="hover:bg-gray-150"
                                    onClick={() => setIsOpen(false)}
                                >
                                    Sobre nosotros
                                </Link>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>


            <div className="container mx-auto flex justify-between items-center hidden lg:flex ">

                <div className="flex justify-start space-x-4 flex-1">
                    <Link to="/" className="font-secondary text-lg min-w-[80px] hover:font-bold">INICIO</Link>

                    <div className="relative">
                        <div className="dropdown">
                            <label
                                tabIndex={0}
                                className="font-secondary text-lg min-w-[120px] flex items-center hover:font-bold cursor-pointer"
                                onClick={toggleDropdown}
                            >
                                SERVICIOS
                                <span className={`ml-2 transition-transform duration-300 ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={2}
                                        stroke="currentColor"
                                        className="w-3 h-3"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </span>
                            </label>
                            {dropdownOpen && (
                                <ul
                                    tabIndex={0}
                                    className="dropdown-content menu p-2 shadow bg-white border border-gray-300 rounded-box w-48 mt-2"
                                >
                                    <li><a href="#" className="text-black hover:bg-gray-150">DIABETES</a></li>
                                    <li><a href="#" className="text-black hover:bg-gray-150">TIROIDES</a></li>
                                    <li><a href="#" className="text-black hover:bg-gray-150">HIPOGLUCEMIA</a></li>
                                    <li><a href="#" className="text-black hover:bg-gray-150">OBESIDAD</a></li>
                                </ul>
                            )}
                        </div>
                    </div>
                    <Link to="/Sobre-nosotros">
                        <a href="#" className="font-secondary text-lg min-w-[160px] hover:font-bold" >SOBRE NOSOTROS</a>
                    </Link>


                </div>
                <div className="flex-none">
                    <Link to="/">
                        <img src={logo} alt="Logo" className="h-10" />
                    </Link>
                </div>
                <div className="flex justify-end space-x-4 flex-1 items-center">
                    <a href="#" className="font-secondary text-lg min-w-[80px] hover:font-bold ">BLOG</a>
                    <a href="#" className="font-secondary text-lg min-w-[160px] bg-[#0d78bc] px-3 py-1 rounded-full text-white flex items-center justify-center transition-colors duration-300 ease-in-out hover:bg-blue-700" onClick={() => navigate('/contacto')}>
                        AGENDA TU CITA
                    </a>


                </div>
            </div>
        </nav>
    );
};

export default Navbar;
