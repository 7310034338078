import React from 'react';
import teresitaPrimary from '../assets/teresitaPrimaria.jpeg';
import teresitaSecondary from '../assets/teresitaSecundaria.png';
import ButtonComponent from '../components/Button';
import WhatsAppButton from '../components/WhatsAppButton';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  let navigate = useNavigate();
  return (
    <div className="flex flex-col min-h-screen w-full text-black bg-white">

      <div className="flex flex-col lg:flex-row w-full">
        <div className="w-full lg:w-1/2 max-h-[40vh] lg:max-h-full overflow-hidden">
          <img src={teresitaPrimary} alt="Consultorio" className="w-full h-full object-cover" />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center overflow-auto">
          <h1 className="text-5xl lg:text-6xl font-primary text-center py-10 sm:mx-5">Consultas de endocrinología en Bogotá</h1>
          <div className="block lg:hidden" onClick={() => navigate('/contacto')}>
            <ButtonComponent text="AGENDA TU CITA" paddingX="64" isBold={true} paddingY="15" letterSize="16" />
          </div>
          <div className="hidden lg:block" onClick={() => navigate('/contacto')}>
            <ButtonComponent text="AGENDA TU CITA" paddingX="18" isBold={true} paddingY="15" letterSize="14"  />
          </div>

        </div>
        <div className="hidden lg:block absolute left-3/4 translate-x-[-50%] translate-y-20 w-3/12 border-b border-black z-10"></div>
      </div>


      <div className="flex flex-col lg:flex-row w-full">
        <div className="w-full lg:w-1/2 max-h-[40vh] lg:max-h-full overflow-hidden">&#8203;</div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center overflow-auto">
          <div className="hidden lg:block mx-auto w-5/12 border-b border-black -mt-5"></div>
        </div>
      </div>



      <div className="flex flex-col w-full p-5 mt-5 items-center">
        <h2 className="font-primary text-4xl lg:text-5xl text-center mb-10">Trata hoy:</h2>
        <div className="grid grid-cols-2 lg:gap-x-96 mb-10 font-secondary text-lg lg:text-xl">
          <ul className="list-disc pl-10">
            {["Diabetes", "Obesidad", "Ovario poliquístico", "Crecimiento", "Menopausia"].map((enfermedad) => (
              <li key={enfermedad} className="my-2">
                <div className="flex flex-col sm:flex-row justify-between items-left py-2 lg:py-2">
                  <span>{enfermedad}</span>
                  <a href="#" className="sm:ml-4 text-sm font-semibold min-w-[100px] border border-white px-2 rounded-full hover:bg-blue-700 hover:text-white hover:border-blue-700">
                    Leer más...
                  </a>
                </div>
              </li>
            ))}
          </ul>
          <ul className="list-disc pl-10">
            {["Tiroides", "Hormonas", "Hipófisis", "Hiperglucemia", "Hipoglucemia"].map((enfermedad) => (
              <li key={enfermedad} className="my-2">
                <div className="flex flex-col sm:flex-row justify-between items-left py-2">
                  <span>{enfermedad}</span>
                  <a href="#" className="sm:ml-4 text-sm font-semibold min-w-[100px] border border-white px-2  rounded-full hover:bg-blue-700 hover:text-white hover:border-blue-700">
                    Leer más...
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className='mx-10 lg:mx-96 -mt-5'>
        <div className="block lg:hidden" onClick={() => navigate('/contacto')}>
          <ButtonComponent text="AGENDA TU CITA AQUÍ" paddingX="10" isBold={true} paddingY="15" letterSize="16" />
        </div>
        <div className="hidden lg:block" onClick={() => navigate('/contacto')}>
          <ButtonComponent text="AGENDA TU CITA AQUÍ" paddingX="80" isBold={true} paddingY="20" letterSize="16" />
        </div>

        <div className="block lg:hidden">
          <ButtonComponent text="LEER SOBRE ENFERMEDADES Y TRATAMIENTOS" paddingX="10" isBold={true} paddingY="10" letterSize="12" />
        </div>
        <div className="hidden lg:block">
          <ButtonComponent text="LEER SOBRE ENFERMEDADES Y TRATAMIENTOS" paddingX="80" isBold={true} paddingY="15" letterSize="15" />
        </div>
      </div>



      <h2 className="font-primary text-4xl lg:text-5xl text-center my-28 mx-5">Sobre la Dra. Teresita Cortés:</h2>
      <div className="hidden lg:flex flex-col lg:flex-row w-full font-secondary text-xl">
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center overflow-auto">
          <ul className="list-disc ml-20 xl:ml-10">
            <li>Endocrinología universidad de Sao Paulo, Brasil (USP)</li>
            <li>Medicina general y cirugía Universidad Nacional de Colombia (UNAL)</li>
            <li>Diplomado terapias no farmacológicas Universidad Juan N. Corpas</li>
            <li>Experiencia en la fuerza aérea colombiana (2 años)</li>
            <li>Más de 20 años de experiencia consulta particular</li>
          </ul>
          <p className='my-5 max-w-[70vw] lg:max-w-[30vw]'>Soy médica endocrinóloga con más de 20 años de experiencia. Trato a los pacientes de una manera integral uniendo la medicina convencional con la medicina natural y la nutrición para obtener los mejores resultados. Atendiendo a los pacientes con la mejor calidad humana, con excelente actitud, y siempre dispuesta a ayudar a mis pacientes en las diferentes patologías como son enfermedades de la tiroides, diabetes, obesidad, baja talla, tumores hipofisarios y de suprarrenal.</p>
        </div>
        <div className="lg:w-2/9 lg:max-h-full overflow-hidden mx-auto lg:ml-40 ">
          <img src={teresitaSecondary} alt="Consultorio" className="w-4/9 h-full object-cover  rounded-xl" />
        </div>
      </div>

      <div className="flex lg:hidden flex-col lg:flex-row w-full font-secondary text-xl mx-auto">

        <div className="lg:w-2/9 lg:max-h-full overflow-hidden  lg:ml-40 mx-auto -mt-10 mb-10 ">
          <img src={teresitaSecondary} alt="Consultorio" className="w-1/2 md:w-4/9 h-full object-cover mx-auto rounded-xl" />
        </div>
        <div className=" w-3/4 lg:w-1/2 flex flex-col justify-center text-center items-center mx-auto overflow-auto">
          <ul className="list-disc pl-5">
            <li>Endocrinología universidad de Sao Paulo, Brasil (USP)</li>
            <li>Medicina general y cirugía Universidad Nacional de Colombia (UNAL)</li>
            <li>Diplomado terapias no farmacológicas Universidad Juan N. Corpas</li>
            <li>Experiencia en la fuerza aérea colombiana (2 años)</li>
            <li>Más de 20 años de experiencia consulta particular</li>
          </ul>
          <p className='my-5 max-w-[70vw] lg:max-w-[30vw]'>Soy médica endocrinóloga con más de 20 años de experiencia. Trato a los pacientes de una manera integral uniendo la medicina convencional con la medicina natural y la nutrición para obtener los mejores resultados. Atendiendo a los pacientes con la mejor calidad humana, con excelente actitud, y siempre dispuesta a ayudar a mis pacientes en las diferentes patologías como son enfermedades de la tiroides, diabetes, obesidad, baja talla, tumores hipofisarios y de suprarrenal.</p>
        </div>
      </div>

      <h2 className="font-primary text-4xl lg:text-5xl text-center mt-24 mb-10">Agenda tu cita</h2>
      <div className="mx-auto w-4/12 border-b border-black -mt-5"></div>
      <div className='max-w-80 mx-auto my-20 '>
        <WhatsAppButton />
      </div>


      <div className="flex flex-col md:flex-row gap-2 md:gap-4 mb-10">
        <div className="flex-1 p-4 text-center max-w-lg mx-auto">
          <h1 className="text-3xl font-normal mb-10">Endomédicos - Dra. Teresita Cortes Segura</h1>
          <p className="text-lg mb-10">Cra. 18 #79-40 consultorio 404, Localidad de Chapinero, Bogotá, Colombia</p>
          <p className="text-lg"><strong>Teléfono:</strong> 3244823917</p>
          <p className="text-lg"><strong>Correo:</strong> endomedicos@gmail.com</p>
        </div>

        <div className="flex-1 p-4 max-w-lg mx-auto">
          <h2 className="text-3xl font-normal mb-10 text-center">Horario</h2>
          <ul className="text-center text-lg">
            <li>Lunes: 08:30 a.m. – 04:00 p.m.</li>
            <li>Martes: 08:30 a.m. - 04:00 p.m.</li>
            <li>Miércoles: 08:30 a.m. – 04:00 p.m.</li>
            <li>Jueves: 08:30 a.m. – 04:00 p.m.</li>
            <li>Viernes: 08:30 a.m. – 04:00 p.m.</li>
            <li>Sábado: 09:00 a.m. – 12:00 p.m.</li>
            <li>Domingo: Cerrado</li>
          </ul>
          <p className="text-center text-lg">Pide la cita con anterioridad desde Whatsapp</p>
        </div>
      </div>
      <div className='mx-10 lg:mx-96 -mt-5 mb-16'>
        <div className="block lg:hidden" onClick={() => navigate('/contacto')}>
          <ButtonComponent text="AGENDA TU CITA AQUÍ" paddingX="10" isBold={true} paddingY="15" letterSize="16" />
        </div>
        <div className="hidden lg:block" onClick={() => navigate('/contacto')}>
          <ButtonComponent text="AGENDA TU CITA AQUÍ" paddingX="80" isBold={true} paddingY="20" letterSize="16" />
        </div>
      </div>


            <Footer></Footer>

    </div>
  );
};

export default HomePage;
