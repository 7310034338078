import teresitaSecondary from '../assets/teresitaSecundaria.png';
import React from 'react';
import Footer from '../components/Footer';

const About = () => {
    return (
        <div>

            <div className="hidden lg:flex flex-col lg:flex-row w-full font-secondary text-xl mb-20">
                <div className="w-full lg:w-1/2 flex flex-col justify-center items-center overflow-auto">
                    <h2 className="font-primary text-4xl lg:text-5xl text-center my-28 mx-5">Sobre la Dra. Teresita Cortés:</h2>
                    <ul className="list-disc ml-20 xl:ml-10">
                        <li>Endocrinología universidad de Sao Paulo, Brasil (USP)</li>
                        <li>Medicina general y cirugía Universidad Nacional de Colombia (UNAL)</li>
                        <li>Diplomado terapias no farmacológicas Universidad Juan N. Corpas</li>
                        <li>Experiencia en la fuerza aérea colombiana (2 años)</li>
                        <li>Más de 20 años de experiencia consulta particular</li>
                    </ul>
                    <p className='my-5 max-w-[70vw] lg:max-w-[30vw]'>Soy médica endocrinóloga con más de 20 años de experiencia. Trato a los pacientes de una manera integral uniendo la medicina convencional con la medicina natural y la nutrición para obtener los mejores resultados. Atendiendo a los pacientes con la mejor calidad humana, con excelente actitud, y siempre dispuesta a ayudar a mis pacientes en las diferentes patologías como son enfermedades de la tiroides, diabetes, obesidad, baja talla, tumores hipofisarios y de suprarrenal.</p>
                </div>
                <div className="lg:w-2/9 lg:max-h-full overflow-hidden mx-auto lg:ml-40  ">
                    <img src={teresitaSecondary} alt="Consultorio" className="w-4/9 h-full  object-cover  rounded-xl" />
                </div>
            </div>

            <div className="flex lg:hidden flex-col lg:flex-row w-full font-secondary text-xl mx-auto">
                <h2 className="font-primary text-4xl text-center mt-20 mx-5">Sobre la Dra. Teresita Cortés:</h2>
                <div className=" overflow-hidden  mx-auto  my-10 ">
                    <img src={teresitaSecondary} alt="Consultorio" className="w-1/2 md:w-4/9 h-full object-cover mx-auto rounded-xl" />
                </div>
                <div className=" w-3/4  flex flex-col justify-center text-center items-center mx-auto overflow-auto">
                    <ul className="list-disc pl-5">
                        <li>Endocrinología universidad de Sao Paulo, Brasil (USP)</li>
                        <li>Medicina general y cirugía Universidad Nacional de Colombia (UNAL)</li>
                        <li>Diplomado terapias no farmacológicas Universidad Juan N. Corpas</li>
                        <li>Experiencia en la fuerza aérea colombiana (2 años)</li>
                        <li>Más de 20 años de experiencia consulta particular</li>
                    </ul>
                    <p className='my-5 max-w-[70vw] lg:max-w-[30vw]'>Soy médica endocrinóloga con más de 20 años de experiencia. Trato a los pacientes de una manera integral uniendo la medicina convencional con la medicina natural y la nutrición para obtener los mejores resultados. Atendiendo a los pacientes con la mejor calidad humana, con excelente actitud, y siempre dispuesta a ayudar a mis pacientes en las diferentes patologías como son enfermedades de la tiroides, diabetes, obesidad, baja talla, tumores hipofisarios y de suprarrenal.</p>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}
export default About;