import React from 'react';

const ButtonComponent = ({ text, paddingX, onClick, isBold, paddingY, letterSize }) => {
  const textStyle = isBold ? 'font-bold text-sm tracking-tighter' : 'font-normal text-sm tracking-tighter';

  const paddingStyle = {
    paddingLeft: `${paddingX}px`,
    paddingRight: `${paddingX}px`,
    paddingTop: `${paddingY}px`,
    paddingBottom: `${paddingY}px`
  };

  const fontSizeStyle = {
    fontSize: `${letterSize}px`
  };

  return (
    <div 
      onClick={onClick} 
      style={{ ...paddingStyle }} 
      className="flex items-center justify-center w-full my-2 transition duration-100 hover:bg-[#0d78bc] hover:border-white group border border-black cursor-pointer"
    >
      <span className="text-center group-hover:text-white">—</span>
      <button 
        style={{ ...fontSizeStyle }}
        className={`mx-3 text-black tracking-wider ${textStyle} group-hover:text-white focus:outline-none`}
        type="button"
      >
        {text}
      </button>
      <span className="text-center group-hover:text-white">—</span>
    </div>
  );
};

export default ButtonComponent;